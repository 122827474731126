@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	font-family: "Exo 2", sans-serif;
}
html {
	overflow-x: hidden;
	min-height: 100%;
}
body{
	min-height: 100vh;

}

#testo img{
	width: 100%;
	height: auto;
}

#testo p{
	font-size: large;
}
/*font-family: 'Lexend', sans-serif;
font-family: "Geologica", sans-serif;
*/

.pallino {
    list-style-type: disc; /* Ensure bullets are shown */
    padding-left: 20px; /* Add some padding to the left */
  }

.pallinoli {
    display: list-item; /* Ensure list items are displayed correctly */
  }
